// components/Datashow.js
import React, { useState, useEffect } from 'react';
import { ref, onValue, remove } from 'firebase/database';
import { dbRealtime } from './firebase'; // Import the Realtime Database
import { Navbar } from './navbar';
import "./Show.css";

function Showsms() {
  const [deviceNames, setDeviceNames] = useState([]);
  const [smsData, setSmsData] = useState({});
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [selectedMessages, setSelectedMessages] = useState(new Set()); // For tracking selected messages

  useEffect(() => {
    document.title = "Page";
  }, []);

  useEffect(() => {
    const dataRef = ref(dbRealtime, 'ReceivedSMS'); // Reference to the 'ReceivedSMS' path in your database

    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const dataObj = snapshot.val();
        setSmsData(dataObj);

        // Compute the last update time for each device
        const deviceNamesWithLastUpdate = Object.keys(dataObj).map(deviceName => {
          const deviceData = dataObj[deviceName];
          
          // Find the latest 'lastUpdate' timestamp from the 'metadata' field of each item
          const lastUpdated = Math.max(
            ...Object.values(deviceData).map(item => {
              const lastUpdateStr = item.lastUpdated;
              if (lastUpdateStr) {
                const timestamp = new Date(lastUpdateStr).getTime();
                return isNaN(timestamp) ? 0 : timestamp;
              }
              return 0; // Default to 0 if 'lastUpdate' is missing or invalid
            })
          );

          return {
            deviceName,
            lastUpdated: lastUpdated > 0 ? new Date(lastUpdated).toLocaleString() : 'Unknown'
          };
        });

        // Sort devices by last update time (latest on top)
        const sortedDeviceNames = deviceNamesWithLastUpdate.sort((a, b) => {
          const timestampA = new Date(a.lastUpdated).getTime();
          const timestampB = new Date(b.lastUpdated).getTime();
          return timestampB - timestampA; // Descending order
        });

        setDeviceNames(sortedDeviceNames);
      } else {
        console.log('No data available.');
      }
    };

    const dataListener = onValue(dataRef, handleDataChange);

    return () => {
      dataListener(); // Unsubscribe the listener
    };
  }, []);

  const handleDeviceClick = (deviceName) => {
    setSelectedDevice(deviceName);
  };

  const handleBackClick = () => {
    setSelectedDevice(null);
    setSelectedMessages(new Set()); // Clear selected messages when going back
  };

  const toggleMessageSelection = (messageId) => {
    setSelectedMessages(prev => {
      const newSelection = new Set(prev);
      if (newSelection.has(messageId)) {
        newSelection.delete(messageId);
      } else {
        newSelection.add(messageId);
      }
      return newSelection;
    });
  };

  const handleDeleteSelectedMessages = () => {
    if (selectedDevice && selectedMessages.size > 0) {
      // Prepare the delete promises for each selected message
      const deletePromises = [];
      selectedMessages.forEach(messageId => {
        const messageRef = ref(dbRealtime, `ReceivedSMS/${selectedDevice}/${messageId}`);
        deletePromises.push(remove(messageRef));
      });

      // Execute all delete promises
      Promise.all(deletePromises)
        .then(() => {
          // Clear selected messages after deletion
          setSelectedMessages(new Set());
          console.log('Selected messages deleted.');
        })
        .catch(error => {
          console.error('Error deleting messages:', error);
        });
    }
  };

  return (
    <div>
      <Navbar />
      <h1 style={{ textAlign: 'center', marginTop: '0px', fontSize: '20px' }}>SMS</h1>
      <p style={{ textAlign: 'center', color: 'green', padding: '0px', marginTop: '-15px' }}>Show here</p>
      <div style={{ marginLeft: '10px', marginRight: '10px' }}>
        {!selectedDevice ? (
          // Display a list of device buttons with last update time
          <div>
            {deviceNames.map(({ deviceName, lastUpdated }) => (
              <button
                key={deviceName}
                onClick={() => handleDeviceClick(deviceName)}
                className='device-button'
              >
                {deviceName} - Last Update: {lastUpdated}
              </button>
            ))}
          </div>
        ) : (
          // Display SMS data for the selected device
          <div>
            <button onClick={handleBackClick} className='back-button'>
              Back to List
            </button>
            <button
              onClick={handleDeleteSelectedMessages}
              className='delete-button'
              disabled={selectedMessages.size === 0}
            >
              Delete Selected Messages
            </button>
            {smsData[selectedDevice] && Object.entries(smsData[selectedDevice]).length > 0 ? (
              // Get the SMS data and reverse the order of entries
              Object.entries(smsData[selectedDevice])
                .reverse() // Reverse the order of the entries
                .map(([key, value], index) => ({
                  key,
                  ...value
                }))
                .map((item, index) => (
                  <div key={index} className='box'>
                    <input
                      type="checkbox"
                      checked={selectedMessages.has(item.key)}
                      onChange={() => toggleMessageSelection(item.key)}
                    />
                    <p><strong>Date/Time:</strong> {item.lastUpdated || 'Unknown'}</p>
                    {Object.entries(item).map(([dataKey, dataValue], dataIndex) => (
                      <p key={dataIndex}>
                        {dataKey.charAt(0).toUpperCase() + dataKey.slice(1)}: {dataValue}
                      </p>
                    ))}
                  </div>
                ))
            ) : (
              <p>No SMS data available.</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Showsms;
